
import Main from './Main.js'
import { ContextProvider } from './state/context';

function AppEntry() {
  return (
    <ContextProvider>
    <Main />
    </ContextProvider>
  )
}

export default AppEntry;
