import { useCustomContext } from '../state/context';
import { Link } from 'react-router-dom'

export default function Navbar() {

    const { bg } = useCustomContext();
    const getContrastColor = (backgroundColor) => {
      // Convert the hex color to RGB
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
      };
    
      // Calculate the relative luminance of a color
      const calculateRelativeLuminance = (rgb) => {
        const [r, g, b] = rgb.map((value) => {
          const srgb = value / 255;
          return srgb <= 0.03928 ? srgb / 12.92 : ((srgb + 0.055) / 1.055) ** 2.4;
        });
    
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
      };
    
      // Determine contrast color based on relative luminance
      const contrastColor = (luminance) => (luminance > 0.4 ? 'black' : 'white');
    
      // Get RGB values from the background color
      const rgb = hexToRgb(backgroundColor);
    
      // Calculate relative luminance
      const luminance = calculateRelativeLuminance(rgb);
    
      // Determine and return contrast color
      return contrastColor(luminance);
    };
    
    
    return(
        <header>
            <div>
            <h1 style={{color: getContrastColor(bg)}}><Link  style={{color: getContrastColor(bg)}} to="/">Blackjack Counter</Link></h1>
            <p style={{color: getContrastColor(bg)}}>Customizable card counter</p>
            </div>
            <ul>
                <li><Link style={{color: getContrastColor(bg)}} to="/">Home</Link></li>
                <li><Link style={{color: getContrastColor(bg)}} to="/setup">Setup</Link></li>
            </ul>
        </header>
    )
}