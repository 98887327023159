import { useState, useEffect } from 'react';
import { useCustomContext } from '../state/context';
import { ImSpades, ImClubs, ImHeart, ImDiamonds } from 'react-icons/im';


export default function Home() {
  const suits = ['Hearts', 'Diamonds', 'Spades', 'Clubs'];
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
  const { numDecks, size } = useCustomContext();
  const [cards, setCards] = useState([]);

  const generateDeck = () => {
    const deck = [];
    for (const suit of suits) {
      for (const value of values) {
        deck.push({ suit, value });
      }
    }
    return deck;
  };

  useEffect(() => {
    const newCards = [];
    for (let i = 0; i < numDecks; i++) {
      newCards.push(...generateDeck());
    }
    setCards(newCards);
  }, [numDecks]);
  
  const toggleCardBackground = (index) => {
    setCards((prevCards) =>
      prevCards.map((card, i) =>
        i === index
          ? {
              ...card,
              isClicked: !card.isClicked,
              backgroundColor: toggleBackgroundColor(card),
            }
          : card
      )
    );
  };

  const toggleBackgroundColor = (card) => {
    if (card.isClicked) {
      return 'white';
    }

    if (card.suit === 'Hearts' || card.suit === 'Diamonds') {
      return 'red';
    } else if (card.suit === 'Spades' || card.suit === 'Clubs') {
      return 'black';
    }

    return 'white';
  };
  return (
    <div className="cards-container">
      {cards.map((card, index) => (
        <>
        <div
          className={`card ${card.isClicked ? 'clicked' : ''}`}
          key={index}
          onClick={() => toggleCardBackground(index)}
          style={{ backgroundColor: card.backgroundColor }}
        >
          <div style={{fontSize: size + "rem"}}>{getIconForSuit(card.suit)}</div>
          <div style={{fontSize: size + "rem"}}>{card.value}</div>
        </div>
        { card.value === "A" && <br style={{clear: "both"}} />}
        </>
      ))}
    </div>
  );
}

const getIconForSuit = (suit) => {
  switch (suit) {
    case 'Hearts':
      return <ImHeart style={{color: "#8B0000"}} />;
    case 'Diamonds':
      return <ImDiamonds style={{color: "#8B0000"}} />;
    case 'Spades':
      return <ImSpades />;
    case 'Clubs':
      return <ImClubs />;
    default:
      return null;
  }
};
