import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context
const Context = createContext();

// Context provider component
export const ContextProvider = ({ children }) => {
  // State for theme and scores
  const [numDecks, setNumDecks] = useState(1);
  const [size, setSize] = useState(1.2);
  const [bg, setBG] = useState('#ccc');
  const [loading, setLoading] = useState(true)
  // Load theme from localStorage on component mount
  
  useEffect(() => {
    setLoading(true)
    let stored = localStorage.getItem('numDecks');
    if (stored) {
      setNumDecks(parseInt(stored, 10)); // Convert to integer
    } else {
      console.log('No values stored under "numDecks".');
    }

    stored = localStorage.getItem('size');
    if (stored) {
      setSize(parseFloat(stored)); // Convert to float
    } else {
      console.log('No values stored under "size".');
    }

    stored = localStorage.getItem('bg');
    if (stored) {
      console.log(stored);
      setBG(stored);
    } else {
      console.log('No values stored under "bg".');
    }
    setLoading(false)
  }, []);

  // Save theme to localStorage whenever it changes
  useEffect(() => {
    if (!loading) {
    localStorage.setItem('numDecks', numDecks.toString()); // Convert to string
    localStorage.setItem('size', size.toString()); // Convert to string
    localStorage.setItem('bg', bg);
    }
  }, [numDecks, size, bg]);

  // Provide the context value to the components
  return (
    <Context.Provider value={{ bg, setBG, numDecks, setNumDecks, size, setSize }}>
      {children}
    </Context.Provider>
  );
};

// Custom hook to consume the context
export const useCustomContext = () => useContext(Context);
