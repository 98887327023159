import { useState, useEffect } from 'react';
import { ImSpades, ImClubs, ImHeart, ImDiamonds } from 'react-icons/im';
import { SketchPicker } from 'react-color';
import { useCustomContext } from '../state/context';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
export default function Setup() {
  const { bg, setBG, numDecks, setNumDecks, size, setSize } = useCustomContext()
  
  const handleSizeChange = (value) => {
    // Handle volume change logic here
    setSize(value/10);
  };

  const handleDeckChange = (value) => {
    // Handle volume change logic here
    setNumDecks(value);
  };

  const getContrastColor = (backgroundColor) => {
      // Convert the hex color to RGB
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
      };
    
      // Calculate the relative luminance of a color
      const calculateRelativeLuminance = (rgb) => {
        const [r, g, b] = rgb.map((value) => {
          const srgb = value / 255;
          return srgb <= 0.03928 ? srgb / 12.92 : ((srgb + 0.055) / 1.055) ** 2.4;
        });
    
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
      };
    
      // Determine contrast color based on relative luminance
      const contrastColor = (luminance) => (luminance > 0.4 ? 'black' : 'white');
    
      // Get RGB values from the background color
      const rgb = hexToRgb(backgroundColor);
    
      // Calculate relative luminance
      const luminance = calculateRelativeLuminance(rgb);
    
      // Determine and return contrast color
      return contrastColor(luminance);
    };
  
  const handleChange = (newColor) => {
    setBG(newColor.hex);
  };

  function decks(val) {
    if (val < 1) {
      alert("Don't go alone, you need a deck")
    } else if (val > 100) {
      alert("Things crash around 100. Also stop trying to ruin my website! :)")
    }
  }



    return (
      <div className="setup">
        <div>
        <h2 style={{color: getContrastColor(bg)}}>Background colour</h2>
      <SketchPicker color={bg} onChange={handleChange} />
        </div>
        <div style={{width: "50%"}}>
          <h2 style={{color: getContrastColor(bg)}}>Number of decks (1 - 100)</h2>
        <Slider
        min={1}
        max={100}
        step={1}
        value={numDecks}
        onChange={handleDeckChange}
      />
      <input type="number" min={1} max={100} value={numDecks} onChange={(e) => decks(e.target.value)} />
        <h2 style={{color: getContrastColor(bg), marginTop: "2rem"}}>Card size</h2>
        <Slider
        min={5}
        max={100}
        step={1}
        value={size*10}
        onChange={handleSizeChange}
      />
      <div className="cards-container">
        <div className="card">
        <div style={{fontSize: size + "rem"}}><ImHeart style={{color: "#8B0000"}} /></div>
          <div style={{fontSize: size + "rem"}}>A</div>
        </div>
     </div>
     </div>
     </div>
    )
}