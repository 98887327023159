
import Home from './pages/Home';
import Setup from './pages/Setup';
import Navbar from './components/Navbar'
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useCustomContext } from './state/context';
function App() {

  const { bg } = useCustomContext();

  return (
    <div style={{backgroundColor: bg, minHeight: "100vh"}}>
    <BrowserRouter>
    <div className="container">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/setup" element={<Setup />} />
          </Routes>
          </div>
    </BrowserRouter>
    </div>
  )
}

export default App;
